/**
 * import
 *
 * @version 0.1 (2021.09.06 : usayama)
 * @version 0.2 (2021.09.27 : usayama) // 不要処理削除
 */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { IoMailOutline } from 'react-icons/io5'
import axios from 'axios'
// conf, action
import { AJAX_ROUTE } from '@config/routers'
import { VALIDATION_PATTERNS } from '@config/validation.patterns'
// components
import Seo from '@components/Tools/Seo'
import ModalLoading from '@components/UIKit/ModalLoading'
import Alert from '@components/UIKit/Alert'
import LabelHtml from '@components/Typo/LabelHtml'
import InputGroup from '@components/Form/InputGroup'
import Button from '@components/UIKit/Button'
// containers
import LayoutContainer from '@containers/LayoutContainer'
import AuthContainer from '@containers/AuthContainer'
// helper
import { getBearer } from '@lib/helpers'
// style, img, elm
// const

/**
 * const
 *
 * @version 0.1 (2021.09.06 : usayama)
 */
const Remove = () => {


	/**
	 * const
	 *
	 * @version 0.1 (2021.09.06 : usayama)
	*/
  const { handleSubmit, 
          register, 
          formState,
          errors } = useForm({ mode: 'onChange' })
	const { t } = useTranslation()

  /** 
   * useState : 
   * 
   * @version 0.1 (2021.09.06 : usayama)
   */
  const [ isLoad, setIsLoad ] = React.useState(false)
  const [ result, setResult ] = React.useState('')

  /** 
   * event : 
   * 
   * @version 0.1 (2021.09.06 : usayama)
   */
  const onSubmit = (d) => {
	  // init
	  setIsLoad(true)
	  setResult('')
	  // action
	  axios.post(AJAX_ROUTE.remove, d, getBearer())
	      .then((res) => {
	        setIsLoad(false)
	        setResult(res.data.status)
	      })
	      .catch((error) => {
	      	error.response && error.response.data && setIsLoad(false)
	      	setResult('error')
	      })
  }

	/**
	 * return
	 *
	 * @version 0.1 (2021.09.06 : usayama)
	 */
	return (
	    <LayoutContainer>
	    	<Seo title={t('title.remove')} />
				<ModalLoading isLoading={isLoad} />
      	<AuthContainer titleLabel="title.remove">
      		{ result && console.log(result) }
      		{ result && <Alert status={result} label={"message." + result + ".remove"} isOpen={true} closeBt={true} /> }
      		{ result !== "success" && <p className="mb-4"><LabelHtml label="text.remove" /></p> }
      		{ result !== "success" && 
				    <div>
				      <form onSubmit={handleSubmit(onSubmit)}>
				        <InputGroup
				          label={<IoMailOutline size={25} />}
				          placeholder="placeholder.remove_email"
				          type="email"
				          name="email"
				          feedback={errors && errors.email}
				          errors={errors && errors.email}
				          className={errors.email && "is-invalid"}
				          inputClassName={errors.email && "is-invalid"}
				          inputRef={register({ required: true, pattern: VALIDATION_PATTERNS.email })}
				         />
				        <div className="form-action">
				          <Button type="submit" color="success" label="remove" disabled={!formState.isValid} />
				        </div>
				      </form>
				    </div>
				 	}
      	</AuthContainer>
	    </LayoutContainer>
	)
}

/**
 * export
 *
 * @version 0.1 (2021.09.06 : usayama)
 */
export default Remove
